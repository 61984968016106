import Joi from "joi-browser";

const card_name = Joi.string().required().min(2).max(100).label("Наименование");
const brand = Joi.string().required().label("Бренд");
const description = Joi.string().required().label("Описание");

// const select = {
//   connection_id
// };

// const insert = {
//   key_name,
//   key,
//   key_v2
// };

const update = {
  card_name,
  brand,
  description,
};

// const activation = {
//   connection_id,
//   active,
// };

const schemas = {
  // select,
  // insert,
  update,
  // activation,
  properties: {
    // card_name,
  }
}

export default schemas;
