import React from "react";

import CardPopUp from "../../pages/cards/cardPopUp";
import BaseTable from "../../../utils/table/table-base";


class CommonTableWb extends BaseTable {

  baseUrl = 'wb';

  deleteColumn = {
    key: "delete",
    content: (movie) => (
      <button
        onClick={() => this.props.onDelete(movie)}
        type="button"
        className="btn btn-danger"
      >
        Danger
      </button>
    ),
  };

  state = {
    tableHeight: document.documentElement.clientHeight - 60,
    allColumnCount: 0,
    selectedColumnCount: 0,
    // columnState: null,
    wbConnectionId: undefined,
    filterModel: {},
    sortModel: [],
    cardPopUpData: null
  }

  extraColumnQueryCallback(query) {
    query.wbConnectionId = this.state.wbConnectionId;
  }

  constructor(props) {
    super(props);

    this.extraColumnQueryCallback = this.extraColumnQueryCallback.bind(this);
    this.onWbConnection = this.onWbConnection.bind(this);

    this.updateColumnDefs = this.updateColumnDefs.bind(this);
    this.onGetCard = this.onGetCard.bind(this);
  }

  gotTableConfigCallback(){
    this.setState({
      wbConnectionId: this.props.usersReducer.userData.wb_connections[0]?.id
    });
  }

  onGridReady = (params) => {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.updateColumnDefs();
  }

  updateColumnDefs = () => {
    let self = this;
    if(this.baseUrl !== 'wb'){
      return;
    }
    const {
      wb_connections,
    } = this.props.usersReducer.userData;

    let {key_exist_v2} = wb_connections
      .filter(con => con.id === parseInt(this.state.wbConnectionId)).shift();

    const setChild = (child) => {
      if(child.field === 'edit'){
        child.cellRenderer = function ({value}) {
          const div = document.createElement('div');
          div.classList.add('edit');
          div.width = 32;
          div.height = 32;
          div.innerText = 'x'
          if (key_exist_v2) {
            div.classList.add('yes');
            div.innerText = `e`;
            div.addEventListener('click', () => {
              self.onGetCard(value);
            });
          }
          return div;
        }
      }
    };

    this.columnDefs.map(groupOrChild => {
      if (groupOrChild.children) {
        for (const child of groupOrChild.children) {
          setChild(child);
        }
      } else {
        setChild(groupOrChild);
      }
    });

    this.api.setColumnDefs(this.columnDefs);
  }

  onGetCard = ({nmid}) => {
    this.setState({
      cardPopUpData: {
        nmid: nmid,
        wbConnectionId: this.state.wbConnectionId,
      }
    });
  }

  onWbConnection = (e) => {
    this.setState({wbConnectionId: e.target.value}, () => {
      this.updateColumnDefs();
      this.api.onFilterChanged();
      this.onSaveGridColumnState();
    });
  }
  renderCommon() {
    return (
      <div className="row ag-theme-balham"
           style={{
             height: this.state.tableHeight,
             width: '100%'
           }}
      >
        <div id="photo-preview"></div>
        {this.renderCardEdit()}
        {this.renderControlPanel()}
        {this.baseUrl === 'wb' && this.state.wbConnectionId && this.renderTable()}
        {this.baseUrl !== 'wb' && this.renderTable()}
      </div>
    );
  }
  renderCardEdit() {
    const {cardPopUpData} = this.state;
    return (
      <React.Fragment>
        {cardPopUpData &&
        <CardPopUp doClose={() => this.setState({cardPopUpData: null})} data={cardPopUpData} />
        }
      </React.Fragment>
    );
  }
  renderControlPanel() {
    return (
      <div className="control-panel">
        {this.renderFilters()}
        {this.renderChips()}
        <div className="etc">
          <div className="btn-group">
            {this.renderConnection()}
          </div>
        </div>
      </div>
    );
  }
  renderConnection() {
    const {
      // user,
      // companies,
      wb_connections,
    } = this.props.usersReducer.userData;
    return (
      <select
        style={{
          height: '20px',
          lineHeight: '20px',
          paddingTop: 0,
          paddingBottom: 0
        }}
        className="form-select form-select-sm"
        aria-label="Default select example"
        value={this.state.wbConnectionId}
        onChange={this.onWbConnection}
      >
        {wb_connections.map((c, i) => (
          <option key={c.id} value={c.id}>{c.key_name}</option>
        ))}
      </select>
    );
  }
}
export default CommonTableWb;
