import React from "react";
import {connect} from "react-redux";
import CommonTableWb from "../../utils/table/table-common-wb";
import {composeImageUrl} from "../../utils/table/image-url";

class SalesTable extends CommonTableWb {

  constructor(props) {
    super(props);
    this.initColumnDefs();
  }

  target = "sales";
  dateField = "date"

  columnFilterDate = [
    "date",
    "lastchangedate",
  ];
  columnFilterInt = [
    'nmid',
    'totalprice',
    'quantity',
    'barcode',
    'discountpercent',
    'orderid',
    'promocodediscount',
    'number',
    'odid',
    'spp',
    'incomeid',
    'forpay',
    'finishedprice',
    'pricewithdisc',
    'isstorno',
    'gnumber',
  ];
  columnFilterText = [
    'supplierarticle',
    'subject',
    'techsize',
    'category',
    'warehouse',
    'brand',
    'spp',
    'warehousename',
    'countryname',
    'oblastokrugname',
    'regionname',
    'saleid',
    'srid',
  ];
  columnFilterSet = [
    // 'issupply',
    // 'isrealization',
  ];

  extraColumn = [
    {
      fieldName: 'photo', getValue: (row) => {
        return [composeImageUrl(row.nmid)];
      }
    },
    {fieldName: 'edit', getValue: (row) => row},
  ];

  columnDefs = [
    {headerName: "Редактировать", field: "edit"}, //
    {headerName: "Фото", field: "photo"}, //
    {headerName: "SKU", field: 'nmid',}, //
    {headerName: "supplierarticle", field: 'supplierarticle',}, //
    {headerName: "Дата", field: 'date',}, //
    {headerName: "Цена forpay", field: 'forpay',},
    {headerName: "Цена finishedprice", field: 'finishedprice',},
    {headerName: "Цена pricewithdisc", field: 'pricewithdisc',},
    {headerName: "% Скидки", field: 'discountpercent',},
    {headerName: "Цена totalprice", field: 'totalprice',},
    {headerName: "Кол-во", field: "quantity"},
    {headerName: "ID Заказа", field: "orderid"},
    {headerName: "saleid", field: "saleid"},
    {headerName: "ID Поставки", field: "incomeid"},
    {headerName: "ID позиции", field: "odid"},
    {headerName: "Размер", field: "techsize"},
    {headerName: "spp", field: "spp"},
    {headerName: "Название", field: "subject"},
    {headerName: "Категория", field: "category"},
    {headerName: "Бренд", field: "brand"},
    {headerName: "Баркод", field: "barcode"},
    {headerName: "isstorno", field: "isstorno"},
    {headerName: "number", field: "number"},
    {headerName: "gnumber", field: "gnumber"},
    {headerName: "srid", field: "srid"},
    {headerName: "regionname", field: "regionname"},
    {headerName: "oblastokrugname", field: "oblastokrugname"},
    {headerName: "countryname", field: "countryname"},
    {headerName: "warehousename", field: "warehousename"},
    {headerName: "promocodediscount", field: "promocodediscount"},
    {headerName: "isSupply", field: "issupply"},
    {headerName: "isRealization", field: "isrealization"},
    {headerName: "lastchangedate", field: 'lastchangedate',},
  ];

  render() {

    return (
      <React.Fragment>
        {/*HI ALL!!!*/}
        {this.renderCommon()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};
const mapDispatchToProps = (dispatch) => ({
  // dispatchFilter: (value) => dispatch(setFilter(value)),
  // dispatchLoadOrders: (query) => dispatch(loadOrders(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SalesTable);
