import React from "react";
import Form from "../../../../common/form";
import {formatDateTime} from "../../../../utils/date";
import './key.css';
import axios from "axios";
import {toast} from "react-toastify";
import {wbKeyUpdate, wbKeyActivation, wbKeyDelete} from "../../../../../store/entities/wb/users";
import {connect} from "react-redux";
import {onShow} from "../../../../../utils/showController";
import schemaKey from "../../schemas/key";

const {REACT_APP_BACKEND_API} = process.env;
const url = "wb/key";

class Key extends Form {
  state = {
    data: {
      connection_id: "",
      key_name: "",
      key_stat_v1: "",
      key_v2: "",
    },
    errors: {},
    isOpenForm: false,
    loadingLocal: false,
  };
  schema = schemaKey.update;

  constructor() {
    super();
    this.handleFormOpen = this.handleFormOpen.bind(this);
    this.handleFormClose = this.handleFormClose.bind(this);
    this.handleFormDelete = this.handleFormDelete.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
  }

  handleFormOpen() {
    this.setState({loadingLocal: true});
    axios.request({
      baseURL: REACT_APP_BACKEND_API,
      url: `${url}`,
      method: 'get',
      params: {
        connection_id: this.props.connection.id
      },
      withCredentials: true,
    }).then((res) => {
      const {data} = res;
      this.setState({
        data: {
          connection_id: this.props.connection.id,
          key_name: data.key_name,
          key_stat_v1: data.key_stat_v1,
          key_v2: data.key_v2
        },
        isOpenForm: true,
        loadingLocal: false
      });
    }).catch(err => {
      toast.error("Error request!");
      this.setState({loadingLocal: false});
    });
  }

  handleFormClose() {
    this.setState({
      isOpenForm: false
    });
  }

  handleFormDelete() {
    if(window.confirm(`Вы хотите удалить ключ "${this.state.data.key_name}"?`)){
      this.props.wbKeyDelete({
        connection_id: this.props.connection.id,
      });
    }
  }

  handleActivation(e) {
    this.props.wbKeyActivation({
      connection_id: this.props.connection.id,
      active: e.target.dataset.type === 'enable',
    });
  }

  doSubmit = async () => {
    this.props.wbKeyUpdate({
      connection_id: this.props.connection.id,
      key_name: this.state.data.key_name,
      key_stat_v1: this.state.data.key_stat_v1,
      key_v2: this.state.data.key_v2,
    });
  };

  render() {
    const {loading} = this.props.usersReducer;
    const {connection, history} = this.props
    const {isOpenForm, loadingLocal} = this.state
    const con = connection;

    return (
      <div className={"card " + (con.active ? "active" : "not-active")}>
        {/*<img className="card-img-top" src="..." alt="Card image cap" />*/}
        <div className="card-body ">
          {(loadingLocal || loading) && <div className="loading"></div>}
          <h5 className="card-title">{con.key_name}</h5>
          <div className="card-text">
            {con.active ? "Включен" : "Выключен"}
            <small className="text-muted">
              <button
                style={{whiteSpace: 'nowrap', fontSize: '14px'}}
                onClick={onShow}
                type="button" data-hi-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Активация
              </button>
              <div className="dropdown-menu" style={{
                minWidth: '50px', right: 80, fontSize: '14px', marginTop: '-1px'}}>
                <a className="dropdown-item" href="#"
                   data-type={con.active ? "disable" : "enable"}
                   onClick={this.handleActivation}
                >{con.active ? "Выключить" : "Включить"}</a>
              </div>
              <button
                onClick={this.handleFormOpen}
                type="button">
                Правка
              </button>
            </small>
          </div>
          {isOpenForm &&
          <form onSubmit={this.handleSubmit}>
            <div className="">
              {this.renderInput("key_name", "Название")}
              {this.renderInput("key_stat_v1", "Ключ АПИ статистики", "text")}
              {this.renderInput("key_v2", "Ключ новое АПИ", "text")}
            </div>
            <div className="">
              {!(loadingLocal || loading) && this.renderButton("Сохранить", "")}
              <button onClick={this.handleFormClose} type="button"
                      // className="btn btn-secondary btn-sm"
              >
                Закрыть
              </button>
              <button onClick={this.handleFormDelete} type="button"
                      // className="btn btn-secondary btn-sm"
              >
                Удалить
              </button>
            </div>
          </form>
          }

          {!isOpenForm &&
          <div className="card-text time">Заказы:
            <small className="text-muted">
              {history['key_id_' + con.id] && formatDateTime(history['key_id_' + con.id]['orders'])}
            </small>
            <br/>
            Продажи:
            <small className="text-muted">
              {history['key_id_' + con.id] && formatDateTime(history['key_id_' + con.id]['sales'])}
            </small>
            <br/>
            Поставки:
            <small className="text-muted">
              {history['key_id_' + con.id] && formatDateTime(history['key_id_' + con.id]['incomes'])}
            </small>
          </div>
          }
          {/*<p className="card-text">Some quick example text to build on the card title and make up the bulk*/}
          {/*  of the card's content.</p>*/}
          {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  wbKeyUpdate: (params) => dispatch(wbKeyUpdate(params)),
  wbKeyDelete: (params) => dispatch(wbKeyDelete(params)),
  wbKeyActivation: (params) => dispatch(wbKeyActivation(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Key);
