import { combineReducers } from "redux";
import authReducer from "./entities/auth";
import replicaReducer from "./entities/wb/replica";
import usersReducer from "./entities/wb/users";

export default combineReducers({
  authReducer,
  replicaReducer,
  usersReducer,
});
