import React from "react";
import {connect} from "react-redux";
import {composeImageUrl} from "../../utils/table/image-url";
import CommonTableWb from "../../utils/table/table-common-wb";

class StocksTable extends CommonTableWb {

  constructor(props) {
    super(props);
    this.initColumnDefs();
  }

  target = "stocks";
  dateField = "lastchangedate"

  columnFilterDate = [
    "lastchangedate",
  ];
  columnFilterInt = [
    'nmid',
    'price',
    'discount',
    'quantity',
    'quantityfull',
    'quantitynotinorders',
    'barcode',
    'daysonsite',
    'inwaytoclient',
    'inwayfromclient',
  ];
  columnFilterText = [
    'supplierarticle',
    'subject',
    'techsize',
    'category',
    'warehousename',
    'warehouse',
    'brand',
    'sccode',
  ];
  columnFilterSet = [
    // 'issupply',
    // 'isrealization',
  ];

  extraColumn = [
    {
      fieldName: 'photo', getValue: (row) => {
        return [composeImageUrl(row.nmid)];
      }
    },
    {fieldName: 'edit', getValue: (row) => row},
  ];

  columnDefs = [
    // {
      // "lastChangeDate": "2022-04-12T06:01:22.627",
      // "supplierArticle": "прихваткабежевый",
      // "techSize": "0",
      // "barcode": "2023345457009",
      // "quantity": 4,
      // "isSupply": true,
      // "isRealization": false,
      // "quantityFull": 4,
      // "quantityNotInOrders": 4,
      // "warehouse": 1,
      // "warehouseName": "Коледино",
      // "inWayToClient": 0,
      // "inWayFromClient": 0,
      // "nmId": 69156458,
      // "subject": "Прихватки",
      // "category": "Дом",
      // "daysOnSite": 12,
      // "brand": "",
      // "SCCode": "Tech",
      // "Price": 350,
      // "Discount": 30
    // },
    {headerName: "Редактировать", field: "edit"},
    {headerName: "Фото", field: "photo"},
    {headerName: "SKU", field: 'nmid',},
    {headerName: "Цена", field: 'price',},
    {headerName: "Скидка", field: 'discount',},
    {headerName: "lastchangedate", field: 'lastchangedate',},
    {headerName: "Артикул", field: "supplierarticle"},
    {headerName: "Название", field: "subject"},
    {headerName: "Размер", field: "techsize"},
    {headerName: "Категория", field: "category"},
    {headerName: "Кол-во", field: "quantity"},
    {headerName: "Кол-во full", field: "quantityfull"},
    {headerName: "Кол-во не в заказе", field: "quantitynotinorders"},
    {headerName: "Баркод", field: "barcode"},
    {headerName: "Склад", field: "warehousename"},
    {headerName: "warehouse", field: "warehouse"},
    {headerName: "Дней на сайте", field: "daysonsite"},
    {headerName: "К клиенту", field: "inwaytoclient"},
    {headerName: "От клиента", field: "inwayfromclient"},
    {headerName: "Бренд", field: "brand"},
    {headerName: "isSupply", field: "issupply"},
    {headerName: "isRealization", field: "isrealization"},
    {headerName: "SCCode", field: "sccode"},
  ];

  render() {

    return (
      <React.Fragment>
        {/*HI ALL!!!*/}
        {this.renderCommon()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};
const mapDispatchToProps = (dispatch) => ({
  // dispatchFilter: (value) => dispatch(setFilter(value)),
  // dispatchLoadOrders: (query) => dispatch(loadOrders(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StocksTable);
