import React from "react";
import {connect} from "react-redux";
// import CommonTable from "../utils/table-common/table-common";
// import BaseTable from "../utils/table-common/table-base";
import CommonTableWb from "../../utils/table/table-common-wb";

class CardsTable extends CommonTableWb {

  constructor(props) {
    super(props);
    this.preInitColumnDefs();
    this.initColumnDefs();
  }

  preInitColumnDefs(){

    const setChild = (column) => {
      if (this.customColumnFilterInt.indexOf(column.field) !== -1) {
        column.floatingFilter = true;
        column.filter = 'agNumberColumnFilter';
        column.filterParams = {
          filterOptions: ['equals'],
          defaultOption: 'equals',
          buttons: ['reset', 'apply'],
          debounceMs: 200,
          refreshValuesOnOpen: true,
        }
      }
    }

    for(const groupOrChild of this.columnDefs){

      if (groupOrChild.children) {
        for (const child of groupOrChild.children) {
          setChild(child);
        }
      } else {
        setChild(groupOrChild);
      }
    }
  }

  getRowsAfter = () => {
    if(this.getRowsFirst){
      const addinGroup = {
        headerName: 'Группа параметров',
        children: []
      }
      for(let colName of Object.keys(this.custonAddinColumn)){
        addinGroup.children.push(this.custonAddinColumn[colName]);
      }
      this.columnDefs.push(addinGroup);
      this.api.setColumnDefs(this.columnDefs);
    }
  }

  extraColumnCallback = (row) => {

    // nomenclatures
    const nmids = [];
    const vendorCodes = [];
    for(const key in row.nomenclatures){
      nmids.push(row.nomenclatures[key].nmId);
      vendorCodes.push(row.nomenclatures[key].vendorCode);
    }
    row['nmid'] = nmids.join(', ');
    row['nomenclatures_point_nmId'] = nmids.join(', ');
    row['nomenclatures_point_vendorCode'] = vendorCodes.join(', ');

    // addin
    let value = [];
    for(const addin of row.addin){
      value = [];
      for(const param of addin.params){
        if(param.units){
          value.push(param.count + param.units);
          continue;
        }
        value.push(param.value);
      }
      const typeReplaced = addin.type.replace(/[^А-Яа-я0-9A-Za-z]+/g, '_');
      row['addin_point_' + typeReplaced] = value.join(', ');

      if(!this.custonAddinColumn[typeReplaced]){
        this.custonAddinColumn[typeReplaced] = {
          headerName: addin.type,
          field: 'addin_point_' + typeReplaced
        }
      }
    }
  }

  target = "cards/list";

  columnFilterDate = [
  ];

  customColumnFilterInt = [
    'imtId',
    'nomenclatures_point_nmId',
  ];
  custonAddinColumn = {};


  columnFilterText = [

  ];
  columnFilterSet = [
    // 'issupply',
    // 'isrealization',
  ];

  extraColumn = [
    {
      fieldName: 'photo',
      getValue: (row) => {
        const srcList = [];
        for(const nom of row.nomenclatures){
          for(const addin of nom.addin){
            if(addin.type === 'Фото'){
              for(const photo of addin.params){
                srcList.push(photo.value)
              }
            }
          }
        }
        return srcList;
      }
    },
    {fieldName: 'edit', getValue: (row) => row},
  ];
  defaultColDef = {
    // sortable: true,
    filter: true,
    resizable: true,
    enableValue: false,
    enablePivot: false,
    enableRowGroup: false,
  }

  // batchID: "00000000-0000-0000-0000-000000000000"
  // countryProduction: "Россия"
  // createdAt: "2022-03-12T00:51:42Z"
  // id: "43dc2f7b-6ea5-5ba0-8d24-2c04b73d05fc"
  // imtId: 52445893
  // imtSupplierId: 0
  // nomenclatures: [{id: "00af7905-a7a6-4fe1-9f41-a5e21a7e6599", nmId: 69156458, vendorCode: "бежевый",…}]
  // object: "Прихватки"
  // parent: "Дом"
  // supplierId: "6912978b-a67b-4cc8-9b73-46b3921058f5"
  // supplierVendorCode: "прихватка"
  // updatedAt: "2022-05-27T18:12:31Z"
  // userId: 0

  columnDefs = [
    {
      headerName: "Номенклатура",
      children: [
        {headerName: "SKU", field: "nomenclatures_point_nmId"},
        {headerName: "Цвет", field: "nomenclatures_point_vendorCode"}
      ]
    },
    {
      headerName: "Общие",
      children: [
        {headerName: "Редактировать", field: "edit"},
        {headerName: "Фото", field: "photo"},
        {headerName: "Страна", field: "countryProduction"},
        {headerName: "createdAt", field: "createdAt"},
        {headerName: "id", field: "id"},
        {headerName: "imtId", field: "imtId"},
        {headerName: "imtSupplierId", field: "imtSupplierId"},
        {headerName: "object", field: "object"},
        {headerName: "parent", field: "parent"},
        {headerName: "supplierId", field: "supplierId"},
        {headerName: "supplierVendorCode", field: "supplierVendorCode"},
        {headerName: "updatedAt", field: "updatedAt"},
        {headerName: "userId", field: "userId"},
      ]
    },
  ];

  render() {

    return (
      <React.Fragment>
        {/*HI ALL!!!*/}
        {this.renderCommon()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};
const mapDispatchToProps = (dispatch) => ({
  // dispatchFilter: (value) => dispatch(setFilter(value)),
  // dispatchLoadOrders: (query) => dispatch(loadOrders(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CardsTable);


// filterParams321: {
//   filterOptions: [
//     'lessThan',
//     {
//       displayKey: 'lessThanWithNulls',
//       displayName: 'Less Than with Nulls',
//       predicate: ([filterValue], cellValue) => cellValue == null || cellValue < filterValue,
//     },
//     'greaterThan',
//     {
//       displayKey: 'greaterThanWithNulls',
//       displayName: 'Greater Than with Nulls',
//       predicate: ([filterValue], cellValue) => cellValue == null || cellValue > filterValue,
//     },
//     {
//       displayKey: 'betweenExclusive',
//       displayName: 'Between (Exclusive)',
//       predicate: ([fv1, fv2], cellValue) => cellValue == null || fv1 < cellValue && fv2 > cellValue,
//       numberOfInputs: 2,
//     }
//   ]
// }

// let a = {
//   addin_point_Бренд: "ILLAMA",
//   addin_point_Вес_товара_с_упаковкой (г): "100г",
//   addin_point_Высота_предмета: "24см",
//   addin_point_Высота_упаковки: "27см",
//   addin_point_Глубина_упаковки: "2см",
//   addin_point_Декоративные_элементы: "петля для подвешивания",
//   addin_point_Количество_предметов в упаковке: "1",
//   addin_point_Комплектация: "Прихватка 1шт.",
//   addin_point_Материал_изделия: "текстиль, ткань, хлопок",
//   addin_point_Назначение_подарка: "подруге, маме, бабушке",
//   addin_point_Наименование: "Прихватка варежка кухонная тканевая для горячего, для дома, для кухни, прихватка рукавица, хлопок",
//   addin_point_Описание: "Завершающий штрих в интерьере кухни:удобная жаропрочная прихватка для горячего из хлопка . Выдерживает до 240 градусов, выдерживает десятки стирок и не меняет цвет и форму. Кухонная прихватка рукавица для удобной работы на кухне. прихватка кухонная набор варежка женские рукавица подарок любимой бабушке горячие кострюли посуда духовки огнеупорная тканевая декор на годовщину день рождение маме подарочная термостойкая жаропрочная текстиль сковородки плита чайник аксессуары девушке хлопок противень женщины печь стеганые горячая ткань семье рисунок дизайн материал нескользящая защита хлопковые мангал гриль дома барбекю комплект юбилей дача дом посуда домашнего цветы подарочная русская пасха палец ручка home камин красный свадьбы печенье ватин длинная горячую перчаткиварежки термостойкиежаропрочные высокая большая дешевая самая готовить жарка декоративная основа цветочный купить двойная перчатка гриль день дома товары крышек под дом тандыра держатель ткань шкафа пальцами",
//   addin_point_Повод: "9 мая, Пасха, для дома; для декора дома",
//   addin_point_Рисунок: "Цветочный декор",
//   addin_point_Тнвэд: "3924100000",
//   addin_point_Ширина_предмета: "15см",
//   addin_point_Ширина_упаковки: "20см"
// }
