import React, {Component} from "react";

import {
  _zeroPad, filterAgGridToBackend, sortAgGridToBackend
} from "../../../table/filterMap";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import queryString from "query-string";
import axios from "axios";
import {toast} from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import './style.css';
import {AG_GRID_LOCALE_RU} from "./locale.ru";
import {formatDate, dateConfig} from "../../../utils/date";

const {REACT_APP_BACKEND_API} = process.env;

class BaseTable extends Component {

  baseUrl = '';
  target = undefined;
  dateField = '';
  kindOfDates = [
    {id: 'today', title: 'За сегодня', label: 'Сегодня'},
    {id: 'yesterday', title: "За вчера", label: 'Вчера'},
    {id: 'start-monday', title: 'С текущего понедельника', label: 'С понедельника'},
    {id: '7 days', title: 'За последние 7 дней', label: '7 дней'},
    {id: 'month', title: 'За последний месяц', label: 'С начала месяца'},
    {id: '30 days', title: 'За последние 30 дней', label: '30 Дней'},
    {id: '90 days', title: 'За последние 90 дней', label: '90 Дней'},
  ];
  currentKindOfDates = {};

  columnFilterDate = [];
  columnFilterInt = [];
  columnFilterText = [];
  columnFilterSet = [];

  pageSize = 90;
  currentPage = 1;
  ignoreSetColumnState = false;

  extraColumn = [];
  extraColumnCallback = null;
  getRowsAfter = null;
  getRowsFirst = true;
  columnDefs = [];
  columnTranslate = {};
  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    enableValue: false,
    enablePivot: false,
    enableRowGroup: false,
  }
  sidebar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: false,
          suppressValues: false,
          suppressPivots: false,
          suppressPivotMode: false,
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        // buttons: ['apply', 'reset']
      }
    ]
  }

  filterOff = ['edit', 'photo'];
  dimensionTimeout = null;
  resizeTimeout = null;

  deleteColumn = {
    key: "delete",
    content: (movie) => (
      <button
        onClick={() => this.props.onDelete(movie)}
        type="button"
        className="btn btn-danger"
      >
        Danger
      </button>
    ),
  };

  state = {
    tableHeight: document.documentElement.clientHeight - 60,
    allColumnCount: 0,
    selectedColumnCount: 0,
    // columnState: null,
    filterModel: {},
    sortModel: [],
    cardPopUpData: null
  }

  gridDatasource = {
    // called by the grid when more rows are required
    getRows: (grid) => {

      const query = this.getQuery(grid);
      if (query.filters) {
        query.filters = JSON.stringify(query.filters);
      }
      const stringified = queryString.stringify(query, {arrayFormat: "bracket"});

      axios.request({
        baseURL: REACT_APP_BACKEND_API,
        url: `${this.baseUrl}/${this.target}?${stringified}`,
        method: 'get',
        withCredentials: true,
      }).then((res) => {
        this.currentPage = parseInt(res.data.currentPage);
        const rowCount = (res.data.rows.length < this.pageSize)
          ? res.data.rows.length + (this.pageSize * (this.currentPage - 1)) : null;

        const rows = [];
        for (const iRow in res.data.rows) {
          const row = {};
          for (const iCol in res.data.cols) {
            row[res.data.cols[iCol]] = res.data.rows[iRow][iCol];
          }
          if(this.extraColumn.length){
            for (const extraColumn of this.extraColumn) {
              row[extraColumn.fieldName] = extraColumn.getValue(row);
            }
          }
          rows.push(row);
        }

        if(this.extraColumnCallback){
          for (const keyRow in rows) {
            this.extraColumnCallback(rows[keyRow]);
          }
        }
        grid.success({
          rowData: rows,
          rowCount
        });

        if(this.getRowsAfter){
          this.getRowsAfter();
        }
        this.getRowsFirst = false;

      }).catch(err => {
        grid.fail();
        toast.error("Error request!");
      });
    },
    // destroy?(): void;
  }

  getQuery(grid) {
    if (!grid) grid = this;
    let filterModel = grid.api.getFilterModel();
    let sortModel = grid.api.getSortModel();
    const filters = filterAgGridToBackend(filterModel);
    const sort = sortAgGridToBackend(sortModel);
    const {pageSize, currentPage} = grid.api.paginationProxy;
    const query = {currentPage: currentPage + 1, pageSize, filters, sort};
    if(this.extraColumnQueryCallback){
      this.extraColumnQueryCallback(query);
    }
    return query;
  }

  updateDimensions = () => {
    clearTimeout(this.dimensionTimeout);
    this.dimensionTimeout = setTimeout(() => {
      this.setState({tableHeight: window.innerHeight - 60});
    }, 500);
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    axios.request({
      baseURL: REACT_APP_BACKEND_API,
      url: `user-table-config?pageOf=${this.baseUrl}/${this.target}`,
      method: 'get',
      withCredentials: true,
    }).then((res) => {
      // this.updateColumn();
      this.setState({
        columnState: res.data.columnState,
      });
      this.gotTableConfigCallback();
    }).catch(err => {
      console.error('onGridReady catch', err);
      toast.error("Error request!");
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  constructor(props) {
    super(props);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.onSaveGridColumnState = this.onSaveGridColumnState.bind(this);
    this.onColumnVisible = this.onColumnVisible.bind(this);
    this.onColumnResized = this.onColumnResized.bind(this);
    this.onFilterDate = this.onFilterDate.bind(this);
    // this.onPageTarget = this.onPageTarget.bind(this);
    this.onDateApply = this.onDateApply.bind(this);
    this.onDateCancel = this.onDateCancel.bind(this);
    // this.onCsv = this.onCsv.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onSortChanged = this.onSortChanged.bind(this);
    this.onChip = this.onChip.bind(this);
    this.setDate = this.setDate.bind(this);
    this.renderFilterDate = this.renderFilterDate.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.gotTableConfigCallback = this.gotTableConfigCallback.bind(this);
    this.setKindOfDates = this.setKindOfDates.bind(this);
    this.initColumnDefs();
  }

  gotTableConfigCallback(){

  }

  initColumnDefs() {
    const setChild = (child) => {
      if(child.field === 'photo'){
        child.cellRenderer = (params) => {
          const divEl = document.createElement('div');
          divEl.classList.add('img-cont');
          for(let imgSrc of params.value){
            const img = document.createElement('img');
            img.classList.add('photo');
            img.width = 32;
            img.height = 32;
            // img.src = `https://img1.wbstatic.net/c252x336/new/${params.data.nmid.substring(0, 4)}0000/${params.data.nmid}-1.jpg`;
            img.src = imgSrc;
            img.addEventListener('mouseenter', function (e) {
              const preview = document.getElementById('photo-preview');
              const top = img.getBoundingClientRect().top;
              const imgClone = img.cloneNode();
              imgClone.width = 270;
              imgClone.height = 350;
              imgClone.style.top = `${top-160}px`;
              imgClone.style.left = `${img.getBoundingClientRect().x + 35}px`;
              preview.appendChild(imgClone);
            });
            img.addEventListener('mouseout', function (e) {
              const preview = document.getElementById('photo-preview');
              preview.innerHTML = '';
            });
            divEl.appendChild(img);
          }
          return divEl;
        }
      }
      if (this.columnFilterInt.indexOf(child.field) !== -1) {
        child.filter = 'agNumberColumnFilter';
        child.floatingFilter = true;
      }
      if (this.columnFilterText.indexOf(child.field) !== -1) {
        child.filter = 'agTextColumnFilter';
        child.floatingFilter = true;
      }
      if (this.columnFilterSet.indexOf(child.field) !== -1) {
        child.filter = 'agSetColumnFilter';
        child.floatingFilter = true;
        child.values = function (params) {

          alert(JSON.stringify(params));
        }
      }
      if (this.columnFilterDate.indexOf(child.field) !== -1) {
        child.filter = 'agDateColumnFilter';
        child.floatingFilter = true;
        // child.valueFormatter = function (params) {}
        child.cellRenderer = function (params) {
          if (!params.value) return null;
          const date = new Date(params.value);
          const str = _zeroPad(date.getDate())
            + '.' + _zeroPad(date.getMonth() + 1)
            + '.' + _zeroPad(date.getFullYear())
            + ' ' + _zeroPad(date.getHours())
            + ':' + _zeroPad(date.getMinutes());
          return str;
          //order_dt
        }
      }

      if(!child.filterParams){
        child.filterParams = {
          buttons: ['reset', 'apply'],
          debounceMs: 200,
          refreshValuesOnOpen: true,
          // values: function (params) {
          //
          //   alert(JSON.stringify(params));
          //
          //   // let asyncValues = fakeServer.getAges();
          //   // setTimeout(function () {
          //   //   params.success(asyncValues);
          //   // }, 500);
          // },
        };
      }
      if(this.filterOff.indexOf(child.field) !== -1){
        child.sortable = false;
        child.filter = false;
      }
    }

    this.columnDefs.map(groupOrChild => {
      if (groupOrChild.children) {
        for (const child of groupOrChild.children) {
          setChild(child);
        }
      } else {
        setChild(groupOrChild);
      }
    });
    this.initColumnTranslate();
  }

  initColumnTranslate(){
    const takeChild = (child) => {
      if(!this.columnTranslate[child.field]){
        this.columnTranslate[child.field] = child.headerName;
      }
    };
    for(let groupOrChild of this.columnDefs){
      if (groupOrChild.children) {
        for (const child of groupOrChild.children) {
          takeChild(child);
        }
      } else {
        takeChild(groupOrChild);
      }
    }
  }

  onFilterChanged(){
    this.setState({filterModel: this.api.getFilterModel()});
  }
  onSortChanged(){
    this.setState({sortModel: this.api.getSortModel()});
  }
  onChip({target}){
    const {typeChip, value} = target.dataset;
    if(typeChip === 'filter'){
      delete this.state.filterModel[value];
      this.api.setFilterModel(this.state.filterModel);
    }
    if(typeChip === 'sort'){
      this.api.setSortModel(this.state.sortModel.filter(s => s.colId !== value));
    }
  }

  onGridReady = (params) => {
    this.api = params.api;
    this.columnApi = params.columnApi;
  }

  onFirstDataRendered = () => {
    const columnsToolPanel = this.api.getToolPanelInstance('columns');
    columnsToolPanel.setPivotModeSectionVisible(false);
    columnsToolPanel.setRowGroupsSectionVisible(false);
    columnsToolPanel.setValuesSectionVisible(false);
    columnsToolPanel.setPivotSectionVisible(false);
    //https://github.com/ag-grid/ag-grid/issues/1785
    //https://streamlit.io/
    this.ignoreSetColumnState = true;
    this.columnApi.setColumnState(this.state.columnState);
    setTimeout(() => this.ignoreSetColumnState = false, 2000);
    // this.columnApi.applyColumnState({state: this.state.userTableConfig});
  }

  onColumnResized() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.onSaveGridColumnState();
    }, 1000);
  }

  onSaveGridColumnState() {
    if (this.ignoreSetColumnState) {
      return;
    }

    const columnState = this.columnApi.getColumnState();

    axios.request({
      baseURL: REACT_APP_BACKEND_API,
      url: `user-table-config?pageOf=${this.baseUrl}/${this.target}`,
      method: 'post',
      data: {
        columnState
      },
      withCredentials: true,
    }).then((res) => {
      // console.log('onSaveGridColumnState then', res.data);
    }).catch(err => {
      console.error('onSaveGridColumnState catch', err);
      toast.error("Error request save grid!");
    });
  }

  onColumnVisible() {
    this.onSaveGridColumnState();
  }

  //by date
  onFilterDate(e) {
    const currentKindOfDate = this.getKindOfDate(e.target.dataset.date);
    this.setDate(currentKindOfDate.dateFrom, currentKindOfDate.dateTo);
    this.setKindOfDates();
  }
  setKindOfDates(e) {
    for(const kindOfDate of this.kindOfDates){
      const dateKey = Object.values(this.getKindOfDate(kindOfDate.id)).join('');
      if (!this.currentKindOfDates[dateKey]){
        this.currentKindOfDates[dateKey] = [kindOfDate.id];
      }
      if (this.currentKindOfDates[dateKey].indexOf(kindOfDate.id) === -1){
        this.currentKindOfDates[dateKey].push(kindOfDate.id);
      }
    }
  }
  getKindOfDate(kindOfDate) {
    const dateFrom = new Date();
    const dateTo = new Date();
    const oneDay = (24 * 60 * 60 * 1000);
    let dateOffset = 0; // 1 day

    switch (kindOfDate) {
      case 'today':
        // dateOffset = (24*60*60*1000) * 5; //5 days
        break;
      case 'yesterday':
        dateOffset = oneDay;
        dateTo.setTime(dateTo.getTime() - dateOffset);
        break;
      case 'start-monday':
        const getDay = (dateFrom.getDay() === 0) ? 7 : dateFrom.getDay();
        dateOffset = oneDay * (getDay - 1);
        break;
      case 'month':
        dateOffset = oneDay * (dateFrom.getDate() - 1);
        break;
      case '7 days':
        dateOffset = oneDay * 7;
        break;
      case '30 days':
        dateOffset = oneDay * 30;
        break;
      case '90 days':
        dateOffset = oneDay * 90;
        break;
    }

    dateFrom.setTime(dateFrom.getTime() - dateOffset);

    let dateFromStr = formatDate(dateFrom);
    let dateToStr = formatDate(dateTo);
    return {dateFrom: `${dateFromStr} 00:00:00`, dateTo: `${dateToStr} 00:00:00`};
    // this.setDate(`${dateFromStr} 00:00:00`, `${dateToStr} 23:59:59`);
    //const model = dateFilterComponent.getModel();
  }
  onDateApply(event, picker) {
    const {startDate, endDate} = picker;
    this.setDate(startDate.format('YYYY-MM-DD 00:00:00'), endDate.format('YYYY-MM-DD 23:59:59'));
    this.setKindOfDates();
  }
  onDateCancel(event, picker) {
    const filterModel = this.api.getFilterModel();
    delete filterModel[this.dateField];
    this.api.setFilterModel(filterModel);
  }
  setDate(dateFrom, dateTo) {
    const dateFilterComponent = this.api.getFilterInstance(this.dateField);
    const newModel = {
      dateFrom,
      dateTo,
      type: 'inRange',
      filterType: 'date'
    }
    dateFilterComponent.setModel(newModel);
    this.api.onFilterChanged();
  }
  ///by date
  renderCommon() {
    return (
      <div className="row ag-theme-balham"
           style={{
             height: this.state.tableHeight,
             width: '100%'
           }}
      >
        <div id="photo-preview"></div>
        {this.renderControlPanel()}
        {this.renderTable()}
      </div>
    );
  }
  renderFilterDate() {
    if(!this.dateField) return;
    let filterModel = {};
    if(this.api){
      filterModel = this.api.getFilterModel();
    }
    const filterDate = filterModel[this.dateField];
    let idActive = [];
    if(filterDate && filterDate.type === 'inRange'){
      const active = this.currentKindOfDates[filterDate.dateFrom+filterDate.dateTo];
      if(active){
        idActive = active;
      }
    }
    return (
      <div>
        {this.kindOfDates.map(kindOfDate => (
          <button
            title={kindOfDate.title}
            data-date={kindOfDate.id}
            onClick={this.onFilterDate}
            className={(idActive.indexOf(kindOfDate.id) !== -1)?"active":""}
          >{kindOfDate.label}</button>
        ))}
        <DateRangePicker
          initialSettings={dateConfig}
          // daterangepicker ltr show-calendar opensleft linked
          onApply={this.onDateApply}
          onCancel={this.onDateCancel}
        >
          <button>Календарь</button>
        </DateRangePicker>
      </div>
    );
  }
  renderFilters() {
    return (
      <div className="filters">
        {this.renderFilterDate()}
      </div>
    );
  }
  renderControlPanel() {
    return (
      <div className="control-panel">
        {this.renderFilters()}
        {this.renderChips()}
      </div>
    );
  }
  renderTable() {
    return (
      <AgGridReact
        serverSideStoreType={"partial"}
        // debug={true}
        rowHeight={28}
        serverSideFilteringAlwaysResets={true}
        sideBar={this.sidebar}
        defaultColDef={this.defaultColDef}
        columnDefs={this.columnDefs}
        // rowData={this.props.rows}
        onGridReady={this.onGridReady}
        onFirstDataRendered={this.onFirstDataRendered}
        rowModelType={'serverSide'}
        serverSideDatasource={this.gridDatasource}
        pagination={true}
        paginationPageSize={this.pageSize}
        cacheBlockSize={this.pageSize}
        localeText={AG_GRID_LOCALE_RU}
        //EVENTS
        // onFirstDataRendered={this.onFirstDataRendered.bind(this)}
        // // state change events
        onFilterChanged={this.onFilterChanged}
        onSortChanged={this.onSortChanged}
        onColumnVisible={this.onColumnVisible}
        onColumnPinned={this.onSaveGridColumnState}
        onColumnResized={this.onColumnResized}
        onColumnMoved={this.onSaveGridColumnState}
        blockLoadDebounceMillis={100}
        // onColumnRowGroupChanged={this.onSaveGridColumnState.bind(this)}
        // onColumnValueChanged={this.onSaveGridColumnState.bind(this)}
        // onColumnPivotChanged={this.onSaveGridColumnState.bind(this)}
        // onColumnPivotModeChanged={this.onSavePivotModeState.bind(this)}
      />
    );
  }
  renderChips() {
    const {filterModel, sortModel} = this.state;
    const filters = Object.keys(filterModel);
    return (
      <div className="chips">
        <div className="chips-filters">
          {filters.map((name) => (
            <div className="chip" key={name} >
              {this.columnTranslate[name] || name}
              <span onClick={this.onChip} data-type-chip="filter" data-value={name} className="closebtn">×</span>
            </div>
          ))}
        </div>
        <div className="chips-sorts">
          {sortModel.map((sort) => (
            <div className="chip" key={sort.colId} >
              {this.columnTranslate[sort.colId] || sort.colId}
              <span onClick={this.onChip} data-type-chip="sort" data-value={sort.colId} className="closebtn">×</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return state.entities;
// };
// const mapDispatchToProps = (dispatch) => ({
//   // dispatchFilter: (value) => dispatch(setFilter(value)),
//   // dispatchLoadOrders: (query) => dispatch(loadOrders(query)),
// });
// export default connect(mapStateToProps, mapDispatchToProps)(CommonTable);
export default BaseTable;
