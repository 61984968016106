import axios from "axios";
import * as actions from "../actions/api";
import { toast } from "react-toastify";
// import { push } from "react-router-redux";
import React from "react";

const { REACT_APP_BACKEND_API } = process.env;

const api =
  ({ dispatch }) =>
    (next) =>
      async (action) => {
        if (action.type !== actions.apiCallBegan.type) return next(action);

        const { url, method, onSuccess, onStart, onError, headers, callback, successMessage } = action.payload;
        let { data } = action.payload;

        if (onStart) dispatch({ type: onStart });

        next(action);
        let statusCode = 0;
        try {

          if(headers && headers['Content-Type'] === 'multipart/form-data'){
            const formData = new FormData();
            const dataKeys = Object.keys(data);
            for(let i=0; i<dataKeys.length; i++){
              formData.append(dataKeys[i], data[dataKeys[i]]);
            }
            data = formData;
          }

          const result = await axios.request({
            baseURL: REACT_APP_BACKEND_API,
            url,
            method,
            headers,
            data,
            onSuccess,
            onError,
            withCredentials: true,
          });
          statusCode = result.status;
          // General
          dispatch(actions.apiCallSuccess({data: result.data, successMessage}));
          // Specific
          if (onSuccess) dispatch({ type: onSuccess, payload: result.data, statusCode });
          if (callback) callback(result.data);
          if (result.data && result.data.messageInfo) {
            toast.info(result.data.messageInfo);
          }
        } catch (err) {

          let payload = err.message;
          if (err.response) {
            if (err.response.status) statusCode = err.response.status;
            if (err.response.data) payload = err.response.data;
          }

          // Dispatch from anywhere like normal.
          // dispatch(push("/login"));
          // window.location.reload();

          // General
          dispatch(actions.apiCallFailed(err.message));
          // Specific
          if (onError) dispatch({type: onError, payload, statusCode});

          if (statusCode === 400) {
            toast.error(JSON.stringify(payload));
          } else {
            toast.error("Error request!");
          }
        }
      };

export default api;
