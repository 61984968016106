import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { Redirect } from "react-router-dom";
import { login } from "../../../store/entities/auth";
import { connect } from "react-redux";

class LoginForm extends Form {
  state = {
    data: {
      username: "",
      password: "",
    },
    errors: {},
  };
  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };
  doSubmit = async () => {
    console.log("doSubmit");
    try {
      const { data } = this.state;
      this.props.login(data.username, data.password);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const { user, loading } = this.props.authReducer;

    // if (user) return <Redirect to="/" />;
    if (user) {
      window.location.href = '/';
    }

    return (
      <div>
        <h1>Login {loading && <img style={{width: '80px'}} src="/spinner.gif" />}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username")}
          {this.renderInput("password", "Password", "password")}
          {!loading && this.renderButton("Login")}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
