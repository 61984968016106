import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../actions/api";

const slice = createSlice({
  name: "replication",
  initialState: {
    history: [],
    loading: false,
  },
  reducers: {
    commonRequested: (state, action) => {
      state.loading = true;
    },
    commonRequestFailed: (state, action) => {
      state.loading = false;
    },

    historyReceived: (state, action) => {
      state.history = action.payload;
      state.loading = false;
    },
  },
});

const {
  commonRequested, commonRequestFailed,

  historyReceived
} =
  slice.actions;
export default slice.reducer;

export const wbHistory = () => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "wb/replication/history",
      method: "get",
      onStart: commonRequested.type,
      onError: commonRequestFailed.type,
      onSuccess: historyReceived.type,
    })
  );
};

