import React from "react";
import {connect} from "react-redux";
import CommonTableWb from "../../utils/table/table-common-wb";
import 'bootstrap-daterangepicker/daterangepicker.css';
import queryString from "query-string";
import axios from "axios";
import moment from "moment";
import {toast} from "react-toastify";
import {onShow} from "../../../../../utils/showController";
import {composeImageUrl} from '../../utils/table/image-url'

const {REACT_APP_BACKEND_API} = process.env;

class OrderSalesTable extends CommonTableWb {

  constructor(props) {
    super(props);
    this.onPageTarget = this.onPageTarget.bind(this);
    this.onCsv = this.onCsv.bind(this);
    this.initColumnDefs();
  }

  target = "order-sales";
  dateField = "o_date"

  columnFilterDate = [
    "o_cancel_dt",
    "o_date",
    "s_sale_date",
    "s_refund_date",
    "o_lastchangedate",
    "createdAt",
    "updatedAt",
  ];
  columnFilterInt = [
    "s_billed_times",
    "s_refunded_times",

    "o_nmid",
    "o_odid",
    "o_barcode",
    "o_quantity",
    "o_totalprice",
    "o_discountpercent",
    "o_incomeid",
    "o_gnumber",
    "o_number",
    "s_orderid",
    "s_promocodediscount",
    "s_spp",
    "s_spp_aggrg",
    "s_forpay",
    "s_finishedprice",
    "s_pricewithdisc",
    "s_isstorno",
    "s_billedmoney",
    "s_final_commission",
    "s_refundedmoney",
    "s_forpay_withlogistic",
  ];
  columnFilterText = [
    "o_subject",
    "o_supplierarticle",
    "o_category",
    "o_brand",
    "o_warehousename",
    "o_oblast",
    "countryname",
    "oblastokrugname",
    "regionname",
    "saleid",
  ];

  extraColumn = [
    {
      fieldName: 'photo',
      getValue: (row) => {
        return [composeImageUrl(row.o_nmid)];
      }
    },
    {fieldName: 'edit', getValue: (row) => row},
  ];

  columnDefs = [
    {
      headerName: "Товар",
      children: [
        {headerName: "Редактировать", field: "edit"},
        {headerName: "Фото", field: "photo"},
        {
          headerName: "Название",
          field: "o_subject",
          // content: (order) => (
          //   <Link to={`/orders/${order.nmid}`}>{order.subject}</Link>
          // ),
        },
        {headerName: "SKU", field: "o_nmid"},
        {headerName: "Категория", field: "o_category"},
        {headerName: "Размер", field: "o_techsize"},
        {headerName: "Баркод", field: "o_barcode"},
        {headerName: "Артикул", field: "o_supplierarticle"},
        {headerName: "Бренд", field: "o_brand"},
        {
          headerName: "Цена",
          headerTooltip: "цена до согласованной скидки/промо/спп",
          field: "o_totalprice"},
      ]
    },
    {
      headerName: "Идентификация",
      children:[
        {headerName: "Номер заказа", field: "o_number"},
      ]
    },
    {
      headerName: "Финансы",
      children:[
        {headerName: "Оплачено", field: "s_billedmoney"},
        {headerName: "Комиссия", field: "s_final_commission"},
        {headerName: "Возврат", field: "s_refundedmoney"},
        {headerName: "К выплате", field: "s_forpay_withlogistic"},
        {headerName: "СПП скидка постоянного покупателя", field: "s_spp_aggrg"},
      ]
    },
    {
      headerName: "Движение заказа",
      children:[
        {headerName: "Заказ", field: "o_date"},
        {headerName: "Продажа", field: "s_sale_date"},
        {headerName: "Возврат", field: "s_refund_date"},
        // from "возрврат" add to cancel_dt
        {headerName: "Обратная логистика", field: "o_cancel_dt"},
      ]
    },
    {
      headerName: "Оплаты и возвраты",
      children:[
        {headerName: "Оплаты", field: "s_billed_times"},
        // from "возрврат" add to cancel_dt
        {headerName: "Возвраты", field: "s_refunded_times"},
      ]
    },
    {
      headerName: "Логистика",
      children:[
        {
          headerName: "Склад",
          headerTooltip: "склад отгрузки",
          field: "o_warehousename"},
        {headerName: "Регион", field: "o_oblast"},
      ]
    },
    {
      headerName: "Поставка",
      children:[
        {headerName: "Номер поставки", field: "o_incomeid"},
      ]
    },
  ];
  columnTranslate = {
    edit: "Редактировать",
    photo: "Фото",
    o_subject: "Название товара",
    o_nmid: "SKU",
    o_category: "Категория",
    o_techsize: "Размер",
    o_barcode: "Баркод",
    o_supplierarticle: "Артикул",
    o_brand: "Бренд",
    o_totalprice: "Цена",
    o_number: "Номер заказа",
    s_billedmoney: "Оплачено",
    s_final_commission: "Комиссия",
    s_refundedmoney: "Возврат",
    s_forpay_withlogistic: "К выплате",
    o_date: "Дата заказа",
    s_sale_date: "Дата продажи",
    s_refund_date: "Дата возврата",
    o_cancel_dt: "Дата отмены",
    s_billed_times: "Продажи",
    s_refunded_times: "Возвраты",
    o_warehousename: "Склад",
    o_oblast: "Регион",
    o_incomeid: "Номер поставки",
  };
  targetButtons = [
    {value: 'orders', title: 'Заказы'},
    {value: 's_billed_times', title: 'Продажи'},
    {value: 's_refunded_times', title: 'Возвраты'},
    // {value: 's_surcharge_times', title: 'Доплаты'},
  ]

  onGetCard = ({o_nmid}) => {
    this.setState({
      cardPopUpData: {
        nmid: o_nmid,
        wbConnectionId: this.state.wbConnectionId,
      }
    });
  }

  onColumnVisible() {
    this.onSaveGridColumnState();
    this.setCountSelected();
  }

  setCountSelected() {
    let allColumnCount = 0;
    let selectedColumnCount = 0;
    if (this.columnApi) {
      const columnState = this.columnApi.getColumnState();
      allColumnCount = columnState.length;
      for (const col of columnState) {
        if (!col.hide) selectedColumnCount++;
      }
    }
    this.setState({allColumnCount, selectedColumnCount});
  }

  onPageTarget(e) {
    const {target} = e.target.dataset;

    let filterModel = this.api.getFilterModel();

    for (const i in this.targetButtons) {
      delete filterModel[this.targetButtons[i].value];
    }

    if (target !== 'orders') {
      filterModel[target] = {
        filterType: 'number',
        type: 'greaterThanOrEqual',
        filter: 1
      };
    }

    this.dateField = 'o_date';
    if (target === 's_billed_times') {
      this.dateField = 's_sale_date';
    } else if (target === 's_refunded_times') {
      this.dateField = 's_refund_date'
    }

    let curDateFilter = null;
    for (let curDateField of ['o_date', 's_sale_date', 's_refund_date']) {
      if (filterModel[curDateField]) {
        curDateFilter = filterModel[curDateField];
        delete filterModel[curDateField];
      }
    }

    if (curDateFilter) {
      filterModel[this.dateField] = curDateFilter;
    }

    this.api.setFilterModel(filterModel);
  }

  getTarget(){
    const {filterModel} = this.state;
    const filters = Object.keys(filterModel);
    let target = 'orders';
    if(filters.includes('s_billed_times')){
      target = 's_billed_times';
    }
    if(filters.includes('s_refunded_times')){
      target = 's_refunded_times';
    }
    return target;
  }

  onDateCancel(event, picker) {
    let filterModel = this.api.getFilterModel();
    for (let curDateField of ['o_date', 's_sale_date', 's_refund_date']) {
      if (filterModel[curDateField]) {
        delete filterModel[curDateField];
      }
    }
    this.api.setFilterModel(filterModel);
  }

  download(filename, csvString) {
    // var json = JSON.stringify(data);
    // var blob = new Blob([json], {type: "octet/stream"});
    // var url  = window.URL.createObjectURL(blob);
    // window.location.assign(url);

    for (const field in this.columnTranslate) {
      csvString = csvString.replace(field, this.columnTranslate[field]);
    }
    // for (const group of this.api.columnModel.columnDefs) {
    //   for (const col of group.children) {
    //     csvString = csvString.replace(col.field, col.headerName);
    //   }
    // }

    let blob = new Blob([csvString], {type: 'text/csv'});
    //window.location.assign(window.URL.createObjectURL(blob));
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  onCsv = (e) => {
    const query = this.getQuery();
    query.csvSelected = e.target.dataset.type;
    query.wbConnectionId = this.state.wbConnectionId;
    if (query.filters) {
      query.filters = JSON.stringify(query.filters);
    }
    const stringified = queryString.stringify(query, {arrayFormat: "bracket"});

    axios.request({
      baseURL: REACT_APP_BACKEND_API,
      url: "wb/order-sales/csv?" + stringified,
      method: 'get',
      withCredentials: true,
    }).then((res) => {
      this.download('WB-Продажи-заказы-' + moment().format('YYYY.MM.DD_HHmmss') + '.csv', res.data);
    }).catch(err => {
      console.error({err});
      toast.error("Error request!");
    });
  }
  onGridReady = (params) => {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.setCountSelected();
    this.updateColumnDefs();
  }

  render() {
    return (
      <React.Fragment>
        {this.renderCommon()}
      </React.Fragment>
    );
  }

  renderControlPanel() {
    const target = this.getTarget();
    return (
      <div className="control-panel">
        <div className="filters">

          <div className="page-target-buttons">
            {this.targetButtons.map((button, key) => {
              return (
                <button key={key} title={button.title} data-target={button.value} onClick={this.onPageTarget}
                        className={(target === button.value) ? 'active' : ''}>{button.title}</button>
              )
            })}
          </div>
          {this.renderFilterDate()}

        </div>

        {this.renderChips()}
        <div className="etc">
          <div className="btn-group">
            {this.renderConnection()}

            <button
              style={{whiteSpace: 'nowrap'}}
              onClick={onShow}
              type="button" data-hi-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Выгрузить csv
            </button>
            <div className="dropdown-menu" style={{marginTop: 20, right: 0}}>
              <a className="dropdown-item" href="#"
                 data-type="all"
                 onClick={this.onCsv}
              >все колонки</a>
              <a className="dropdown-item" href="#"
                 data-type="custom"
                 onClick={this.onCsv}
              >только выбранные {this.state.selectedColumnCount}/{this.state.allColumnCount}</a>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};
const mapDispatchToProps = (dispatch) => ({
  // dispatchFilter: (value) => dispatch(setFilter(value)),
  // dispatchLoadOrders: (query) => dispatch(loadOrders(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderSalesTable);

