import React, {Component} from "react";
import { connect } from "react-redux";
import {NavLink} from "react-router-dom";

class Welcome extends Component {

  render() {
    const {
      userData,
    } = this.props.usersReducer;

    return (
      <div style={{margin: '20px'}}>
        {!userData &&
          <h3 style={{marginBottom: '20px'}}>
            <small style={{margin: '30px 0', display: 'block'}} className="text-muted">Сервис статистики</small>
            Вам необходимо авторизоваться
          </h3>
        }
        {userData &&
          <React.Fragment>
            <h3 style={{marginBottom: '20px'}}><small className="text-muted">Добренько</small> {userData.user.name}</h3>
            {!userData.wb_connections.length &&
              <React.Fragment>
                <div>Для работы с Wildberries вам необходимо добавить ключ</div>
              </React.Fragment>
            }
            <NavLink style={{padding: 0}} className="nav-item nav-link" to="/wb/add-key" exact>
              Перейти на страницу добавления ключей
            </NavLink>
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  // login: (username, password) => dispatch(login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
