import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    userRegistration: null,
    loading: false,
  },
  reducers: {
    commonRequested: (state, action) => {
      state.loading = true;
    },
    commonRequestFailed: (state, action) => {
      state.loading = false;
    },

    loginReceived: (state, action) => {
      state.user = action.payload;
      state.loading = false;
      window.location.href = '/';
    },
    logoutReceived: (state, action) => {
      state.loading = false;
      window.location.href = '/';
    },
    registrationReceived: (state, action) => {
      state.userRegistration = action.payload;
      state.loading = false;
    },
  },
});

const {
  commonRequested, commonRequestFailed,
  loginReceived,
  logoutReceived,
  registrationReceived,
} = slice.actions;
export default slice.reducer;

export const login = (username, password) => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "auth/login",
      method: "post",
      data: { email: username, password },
      onStart: commonRequested.type,
      onError: commonRequestFailed.type,
      onSuccess: loginReceived.type,
    })
  );
};

export const logout = () => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "auth/logout",
      method: "post",
      onStart: commonRequested.type,
      onError: commonRequestFailed.type,
      onSuccess: logoutReceived.type,
    })
  );
};

export const registration = (username, password, email) => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "auth/registration",
      method: "post",
      data: { first_name: username, password, email },
      onStart: commonRequested.type,
      onError: commonRequestFailed.type,
      onSuccess: registrationReceived.type,
    })
  );
}

// export const getUser = createSelector(
//   (state) => state.entities.authReducer,
//   (state) => state
// );
