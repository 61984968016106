const closeList = [];

const onHide = () => {
  for (const key in closeList) {
    if (!closeList[key]) continue;
    switch (closeList[key].type) {
      case 'dropdown':
        closeList[key].target.parentNode.classList.remove('show');
        closeList[key].target.nextSibling.classList.remove('show');
        break;
    }
    delete closeList[key];
  }
}

window.document.documentElement.addEventListener('click', onHide);
console.log('init show controller');

export const onShow = (e) => {
  e.stopPropagation();
  switch (e.target.dataset.hiToggle) {
    case 'dropdown':
      e.target.parentNode.classList.toggle('show');
      e.target.nextSibling.classList.toggle('show');
      closeList.push({type: e.target.dataset.hiToggle, target: e.target});
      break;
  }
}



