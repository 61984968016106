import React from "react";
import {connect} from "react-redux";
import CommonTable from "../utils/table/table-base";

class AdminIndex extends CommonTable {

  constructor(props) {
    super(props);
    this.initColumnDefs();
  }

  baseUrl = "admin";
  target = "users";
  dateField = "updated_at"

  columnFilterDate = [
    "created_at",
    "updated_at",
  ];
  columnFilterInt = [
  ];
  columnFilterText = [
    'first_name',
    'last_name',
  ];
  columnFilterSet = [
  ];

  extraColumn = [
  ];

  columnDefs = [
    {headerName: "first_name", field: 'first_name',},
    {headerName: "last_name", field: 'last_name',},
    {headerName: "created_at", field: 'created_at',},
    {headerName: "updated_at", field: "updated_at"},
  ];

  render() {
    return (
      <React.Fragment>
        {this.renderCommon()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};
const mapDispatchToProps = (dispatch) => ({
  // dispatchFilter: (value) => dispatch(setFilter(value)),
  // dispatchLoadOrders: (query) => dispatch(loadOrders(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminIndex);
