import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {logout} from "../../store/entities/auth";

class NavBar extends Component {
  render() {
    const {
      userData,
    } = this.props.usersReducer;

    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="#"></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/" exact>
                    Главная
                  </NavLink>
                </li>
                {userData &&
                  <React.Fragment>
                    {!!userData.wb_connections.length &&
                    <React.Fragment>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/wb/order-sales" exact>
                          Заказы и продажи
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/wb/sales" exact>
                          Продажи(возвраты)
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/wb/stocks" exact>
                          Склад
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/wb/cards" exact>
                          Карточки товара
                        </NavLink>
                      </li>
                    </React.Fragment>
                    }
                  </React.Fragment>
                }
                {/*<li className="nav-item">*/}
                {/*  <a className="nav-link" href="#">Link</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item dropdown">*/}
                {/*  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"*/}
                {/*     data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*    Dropdown*/}
                {/*  </a>*/}
                {/*  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">*/}
                {/*    <li><a className="dropdown-item" href="#">Action</a></li>*/}
                {/*    <li><a className="dropdown-item" href="#">Another action</a></li>*/}
                {/*    <li>*/}
                {/*      <hr className="dropdown-divider"/>*/}
                {/*    </li>*/}
                {/*    <li><a className="dropdown-item" href="#">Something else here</a></li>*/}
                {/*  </ul>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*  <a className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">Disabled</a>*/}
                {/*</li>*/}
              </ul>
              <div className="d-flex">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {!userData && (
                  <React.Fragment>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/login">
                        Вход
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/registration">
                        Регистрация
                      </NavLink>
                    </li>
                    {/*<NavLink className="nav-item nav-link" to="/register">*/}
                    {/*  Register*/}
                    {/*</NavLink>*/}
                  </React.Fragment>
                )}
                {userData &&
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/" onClick={this.props.logout}>
                      Выход
                    </NavLink>
                  </li>
                }
                </ul>
              </div>
            </div>
          </div>
        </nav>

            {/*<NavLink className="nav-item nav-link" to="/customers">*/}
            {/*  Customers*/}
            {/*</NavLink>*/}
            {/*<NavLink className="nav-item nav-link" to="/rentals">*/}
            {/*  Rentals*/}
            {/*</NavLink>*/}
            {/*<NavLink className="nav-item nav-link" to="/counter">*/}
            {/*  Counter*/}
            {/*</NavLink>*/}

            {/*{user && (*/}
            {/*  <React.Fragment>*/}
            {/*    <NavLink className="nav-item nav-link" to="/profile">*/}
            {/*      {user.name}*/}
            {/*    </NavLink>*/}
            {/*    /!*<NavLink className="nav-item nav-link" to="/logout">*!/*/}
            {/*    /!*  Logout*!/*/}
            {/*    /!*</NavLink>*!/*/}
            {/*  </React.Fragment>*/}
            {/*)}*/}

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

