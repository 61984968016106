import {createSlice} from "@reduxjs/toolkit";
import {apiCallBegan} from "../../actions/api";

const slice = createSlice({
  name: "users",
  initialState: {
    selectedCompany: null,
    userData: null,
    loading: true,
    statusCode: 0,
    formWbKeyIsOpen: false
  },
  reducers: {
    commonRequested: (state, action) => {
      state.loading = true;
    },
    commonRequestFailed: (state, action) => {
      state.loading = false;
    },

    userDataRequestFailed: (state, action) => {
      state.loading = false;
      state.statusCode = action.statusCode;
    },
    userDataReceived: (state, action) => {
      state.selectedCompany = action.payload.companies[0];
      state.userData = action.payload;
      state.loading = false;
      state.statusCode = action.statusCode;
    },

    wbKeyInsertReceived: (state, action) => {
      state.loading = false;
      state.statusCode = action.statusCode;
      if(action.payload.wb_connection){
        const {id} = action.payload.wb_connection;
        if(!state.userData.wb_connections.filter(con => con.id === id).length){
          state.userData.wb_connections.push(action.payload.wb_connection);
        }
      }
      state.formWbKeyIsOpen = false;
    },
    wbKeyUpdateReceived: (state, action) => {
      state.loading = false;
      state.statusCode = action.statusCode;
      if(action.payload.wb_connection){
        const {id} = action.payload.wb_connection;
        for(const con of state.userData.wb_connections){
          if(con.id === id){
            con.key_name = action.payload.wb_connection.key_name
          }
        }
      }
    },
    wbKeyDeleteReceived: (state, action) => {
      state.loading = false;
      state.statusCode = action.statusCode;
      const {id} = action.payload;
      if(id){
        state.userData.wb_connections = state.userData.wb_connections.filter(con => con.id !== id);
      }
      state.formWbKeyIsOpen = false;
    },
    wbKeyActivationReceived: (state, action) => {
      state.loading = false;
      state.statusCode = action.statusCode;
      if(action.payload.wb_connection){
        const {id} = action.payload.wb_connection;
        for(const con of state.userData.wb_connections){
          if(con.id === id){
            con.active = action.payload.wb_connection.active
          }
        }
      }
    },
    formWbKeyToggle: (state, action) => {
      state.formWbKeyIsOpen = !state.formWbKeyIsOpen;
    },
  },
});

export const {
  commonRequested, commonRequestFailed,

  userDataRequestFailed,
  userDataReceived,
  wbKeyInsertReceived,
  wbKeyUpdateReceived,
  wbKeyDeleteReceived,
  wbKeyActivationReceived,
  formWbKeyToggle
} = slice.actions;
export default slice.reducer;

export const userData = () => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "auth/user-data",
      method: "get",
      onStart: commonRequested.type,
      onError: userDataRequestFailed.type,
      onSuccess: userDataReceived.type,
    })
  );
};

export const wbKeyInsert = (params) => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "wb/key",
      method: "post",
      data: params,
      onStart: commonRequested.type,
      onError: userDataRequestFailed.type,
      onSuccess: wbKeyInsertReceived.type,
    })
  );
};

export const wbKeyUpdate = (params) => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "wb/key",
      method: "put",
      data: params,
      onStart: commonRequested.type,
      onError: userDataRequestFailed.type,
      onSuccess: wbKeyUpdateReceived.type,
    })
  );
};

export const wbKeyDelete = (params) => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "wb/key",
      method: "delete",
      data: params,
      onStart: commonRequested.type,
      onError: userDataRequestFailed.type,
      onSuccess: wbKeyDeleteReceived.type,
    })
  );
};

export const wbKeyActivation = (params) => (dispatch, getStore) => {
  return dispatch(
    apiCallBegan({
      url: "wb/key/activation",
      method: "post",
      data: params,
      onStart: commonRequested.type,
      onError: userDataRequestFailed.type,
      onSuccess: wbKeyActivationReceived.type,
    })
  );
};
