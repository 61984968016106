import { toast } from "react-toastify";

const notify = (store) => (next) => (action) => {
  if (action.type === "onError") {
    console.log("Toastify: ", action.payload.message);
    toast.error("Middleware notify Error!");
  } else return next(action);
};

export default notify;
