import React, {Component} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import "./App.css";
import LoginForm from "../pages/auth/loginForm";
import RegistrationForm from "../pages/auth/registrationForm";
import NavBar from "../header/navbar";
import {connect} from "react-redux";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import StocksTable from "../pages/wb/pages/stocks/table";
import {userData} from "../../store/entities/wb/users";
import Welcome from "../pages/welcome/welcome";
import Keys from "../pages/wb/pages/connections/keys";
import CardsTable from "../pages/wb/pages/cards/table";
import OrderSalesTable from "../pages/wb/pages/order-sales/table";
import AdminIndex from "../pages/admin/adminIndex";
import SalesTable from "../pages/wb/pages/sales/table";

class App extends Component {

  componentDidMount() {
    this.props.userData();
  }

  render() {

    const {userData, loading, statusCode} = this.props.usersReducer;

    return (
      <React.Fragment>
        <ToastContainer/>
        <NavBar />
        {statusCode === 403 &&
          <Redirect to="/" />
        }
        <main className="container-fluid">
          <Switch>
            <Route exact path="/" component={Welcome} />
            {!userData &&
              <React.Fragment>
                <Route path="/login" component={LoginForm}/>
                <Route path="/registration" component={RegistrationForm}/>
              </React.Fragment>
            }
            {/*<Route path="/logout" component={Logout} />*/}
            {/*<ProtectedRoute path="/movies/:id" component={MovieForm} />} />*/}
            {userData &&
              <React.Fragment>
                {!!userData.wb_connections.length &&
                  <React.Fragment>
                    <Route
                      path="/wb/order-sales"
                      render={(props) => (
                        <OrderSalesTable {...props} user={{name: "Andrey"}}/>
                      )}
                    />
                    <Route
                      path="/wb/sales"
                      render={(props) => (
                        <SalesTable {...props}/>
                      )}
                    />
                    <Route
                      path="/wb/stocks"
                      render={(props) => (
                        <StocksTable {...props}/>
                      )}
                    />
                    <Route
                      path="/wb/cards"
                      render={(props) => (
                        <CardsTable {...props}/>
                      )}
                    />
                  </React.Fragment>
                }
                <Route path="/wb/add-key" component={Keys} />
                {/*<Redirect from="/" exact to="/orders"/>*/}
                <Route path="/admin" component={AdminIndex} />
              </React.Fragment>
            }
            {/*<Route path="/customers" component={Customers} />*/}
            {/*<Route path="/rentals" component={Rentals} />*/}
            {/*<Route path="/counter" component={Counter} />*/}
            {/*<Route path="/not-found" component={NotFound} />*/}
            {/*<Redirect to="/not-found" />*/}
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  userData: () => dispatch(userData())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

// <button
//   type="button"
//   className="btn btn-primary"
//   data-bs-toggle="modal"
//   data-bs-target="#exampleModal"
//   onClick={() => showModal("show")}
// >
//   Launch demo modal
// </button>
//
// <div className={`modal ${isShow}`} tabIndex="-1">
//   <div className="modal-dialog">
//     <div className="modal-content">
//       <div className="modal-header">
//         <h5 className="modal-title">Modal title</h5>
//         <button
//           type="button"
//           className="btn-close"
//           data-bs-dismiss="modal"
//           aria-label="Close"
//         ></button>
//       </div>
//       <div className="modal-body">
//         <p>Modal body text goes here.</p>
//       </div>
//       <div className="modal-footer">
//         <button
//           type="button"
//           className="btn btn-secondary"
//           data-bs-dismiss="modal"
//         >
//           Close
//         </button>
//         <button type="button" className="btn btn-primary">
//           Save changes
//         </button>
//       </div>
//     </div>
//   </div>
// </div>
