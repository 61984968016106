import axios from "axios";

const setInterceptor = (store) => {
  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const {entities} = store.getState();
    const {selectedCompany} = entities.usersReducer;
    if(selectedCompany){
      const fieldData = (config.method === 'get') ? 'params' : 'data';
      if(config[fieldData]){
        config[fieldData].selectedCompanyId = selectedCompany.id;
      }else{
        config[fieldData] = {selectedCompanyId: selectedCompany.id};
      }
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
}

export {
  setInterceptor
}
