import React from "react";
import Form from "../../../../common/form";
import schemaCard from "../../../wb/schemas/card";
import axios from "axios";
import {toast} from "react-toastify";
import {composeImageUrl} from "../../utils/table/image-url";

const {REACT_APP_BACKEND_API} = process.env;

class CardPopUp extends Form {

  state = {
    data: {
      card_name: "",
      brand: "",
      description: "",
    },
    errors: {},
    responseCardForShow: null,
    loading: false
  }
  schema = schemaCard.update;

  responseData = null;

  constructor() {
    super();
    this.onGetCard = this.onGetCard.bind(this);
    this.onSetCard = this.onSetCard.bind(this);
    this.getAddin = this.getAddin.bind(this);
  }

  componentDidMount() {

    this.onGetCard({
      nmid: this.props.data.nmid,
      wbConnectionId: this.props.data.wbConnectionId,
    });

    console.log('componentDidMount')
  }

  componentWillUnmount() {
    console.log('componentWillUnmount')
  }

  getAddin(name) {
    const cards = this.responseData?.result?.cards;

    if(cards && cards.length){

      for(const addinItem of cards[0].addin){
        if(addinItem.type === name){
          this[name] = addinItem.params[0].value;
          return addinItem.params[0].value;
        }
      }
    }

    let jsonText = '';
    if(this.state.responseCardForShow){
      jsonText = JSON.stringify(this.state.responseCardForShow);
    }

    toast.error(`Addin Not Found! ${jsonText}`);
    this.props.doClose();
  }

  setAddin(name, value) {
    const cards = this.responseData?.result?.cards;

    if(cards.length){

      for(const addinItem of cards[0].addin){
        if(addinItem.type === name){
          this[name] = addinItem.params[0].value;
          addinItem.params[0].value = value;
          return true;
        }
      }
    }

    return false;
  }

  onGetCard = ({nmid, wbConnectionId}) => {
    this.setState({loading: true});
    axios.request({
      baseURL: REACT_APP_BACKEND_API,
      url: 'wb/cards/get-one',
      method: 'post',
      data: {
        connection_id: parseInt(wbConnectionId),
        nmid: parseInt(nmid)
      },
      withCredentials: true,
    }).then((res) => {

      // setTimeout(() => {
      //   this.responseData = res.data;
      //   this.setState({
      //     data: {
      //       card_name: this.getAddin("Наименование")
      //     },
      //     loading: false
      //   });
      // }, 3000);
      // return

      if(res.data.status !== 200){
        this.setState({
          responseCardForShow: res.data
        });
      }

      this.responseData = res.data.data;
      this.setState({
        data: {
          card_name: this.getAddin("Наименование"),
          brand: this.getAddin("Бренд"),
          description: this.getAddin("Описание"),
        },
        loading: false
      });

    }).catch(err => {
      console.error('onGetCard catch', err);
      toast.error("Error request!");
    });
  }

  onSetCard = ({nmid, wbConnectionId}) => {
    this.setState({loading: true});
    this.setAddin("Наименование", this.state.data.card_name);
    this.setAddin("Бренд", this.state.data.brand);
    this.setAddin("Описание", this.state.data.description);

    axios.request({
      baseURL: REACT_APP_BACKEND_API,
      url: 'wb/cards/set-one',
      method: 'post',
      data: {
        connection_id: parseInt(wbConnectionId),
        nmid: parseInt(nmid),
        cardData: this.responseData
      },
      withCredentials: true,
    }).then((res) => {

      // setTimeout(() => {
      //   this.setState({
      //     responseCardForShow: res.data,
      //     loading: false
      //   });
      // }, 3000);
      // return

      this.setState({
        responseCardForShow: res.data,
        loading: false
      });
    }).catch(err => {
      console.error('onSetCard catch', err);
      toast.error("Error request!");
    });
  }

  doSubmit = async () => {
    console.log('doSubmit')
    this.onSetCard({
      nmid: this.props.data.nmid,
      wbConnectionId: this.props.data.wbConnectionId,
    });
  };

  render() {

    const {nmid} = this.props.data
    const {responseCardForShow, loading} = this.state;

    return (
      <div>
        <div
          className="modal fade show"
          style={{display: 'block'}}
          tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Редактирование товара {loading && <img style={{width: '80px'}} src="/spinner.gif" />}</h5>
                <button onClick={this.props.doClose} type="button" className="close" data-dismiss="modal"
                        aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="modal-body">

                  <img
                    style={{width: 100, height: 100}}
                    className="img-thumbnail"
                    src={composeImageUrl(nmid)}
                    alt=""/>
                  <div>
                    SKU {nmid}
                  </div>
                  <hr />
                  <div
                    className={(this.state.data.card_name.length > 100) ? "card-name-count over-limit" : "card-name-count"} >
                    {this.state.data.card_name.length}
                  </div>
                  {this.responseData && (
                    <React.Fragment>
                      {this.renderInput("card_name", "Наименование")}
                      {this['Наименование']}
                      <hr />
                      {this.renderInput("brand", "Бренд")}
                      {this['Бренд']}
                      <hr />
                      {this.renderTextArea("description", "Описание")}
                      {this['Описание']}
                    </React.Fragment>
                  )}
                  <hr />

                  {responseCardForShow && (
                    <div>

                    <h5>
                      Ответ от WB:
                    </h5>
                      <h6>
                        {JSON.stringify(responseCardForShow)}
                      </h6>
                    </div>
                  )}

                </div>
                <div className="modal-footer">
                  {this.responseData && !loading && this.renderButton("Сохранить")}
                  <button onClick={this.props.doClose} type="button" className="btn btn-secondary"
                          data-dismiss="modal">Закрыть
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

export default CardPopUp;
