import Joi from "joi-browser";

const connection_id = Joi.number().required().label("Идентификатор подключения");
const key_name = Joi.string().required().min(2).max(100).label("Название ключа");
const key_stat_v1 = Joi.string().required()
  .min(393).max(393)
  .required().label("Ключ");
const key_v2 = Joi.string()
  .allow('').allow(null)
  .min(393).max(393)
  .label("Ключ новое АПИ");
const active = Joi.bool().required().label("Активация");

const select = {
  connection_id
};

const insert = {
  key_name,
  key_stat_v1,
  key_v2
};

const update = {
  connection_id,
  key_name,
  key_stat_v1,
  key_v2
};

const activation = {
  connection_id,
  active,
};

const schemas = {
  // select,
  insert,
  update,
  // activation,
  // properties: {
  //   connection_id,
  //   key_name,
  //   key,
  //   key_v2
  // }
}

export default schemas;
