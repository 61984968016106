import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { Redirect } from "react-router-dom";
import { registration } from "../../../store/entities/auth";
import { connect } from "react-redux";
import AuthCode from "./authCode";

class RegistrationForm extends Form {
  state = {
    data: {
      username: "",
      password: "",
      password_confirmation: "",
      email: "",
    },
    errors: {},
    authCodeOpen: false
  };
  schema = {
    username: Joi.string().required().label("Ваше имя"),
    password: Joi.string().required()
      .min(6).max(15)
      .required().label("Пароль"),
    // https://github.com/sideway/joi/blob/master/API.md#stringemailoptions
    password_confirmation: Joi.string().valid(Joi.ref('password'))
      .required()
      .options({ language: { any: { allowOnly: 'Пароли не совпадают' } } }),
    email: Joi.string().email().required().label("Электронная почта"),
  };
  constructor() {
    super();
    this.authCodeOpen = this.authCodeOpen.bind(this);
  }
  authCodeOpen() {
    this.setState({authCodeOpen: !this.state.authCodeOpen});
  }
  validateProperty = ({ name, value }) => {
    const target = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if(name === 'password_confirmation'){
      target['password'] = this.state.data.password;
      schema['password'] = this.schema.password;
    }
    const { error } = Joi.validate(target, schema);
    return error ? error.details[0].message : null;
  };
  doSubmit = async () => {
    console.log("doSubmit");
    try {
      const { data } = this.state;
      this.props.registration(data.username, data.password, data.email);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const { userRegistration, loading } = this.props.authReducer;
    const {authCodeOpen} = this.state;

    if (userRegistration) return <Redirect to="/login"/>;

    return (
      <div>
        <h1>Регистрация {loading && <img style={{width: '80px'}} src="/spinner.gif" />}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("email", "Электронная почта", "email")}
          {this.renderInput("password", "Пароль", "password")}
          {this.renderInput("password_confirmation", "Повторите пароль", "password")}
          {this.renderInput("username", "Ваше имя")}
          {!loading && this.renderButton("Зарегистрироваться")}
        </form>
        {/*<button onClick={this.authCodeOpen} >code</button>*/}
        {/*{authCodeOpen && <AuthCode/>}*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  registration: (username, password, email) => dispatch(registration(username, password, email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
