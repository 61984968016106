import * as _ from "lodash";

// Option Name	Option Key	Supported Filters
// Equals	equals	Text, Number, Date
// Not Equals	notEqual	Text, Number, Date
// Contains	contains	Text
// Not Contains	notContains	Text
// Starts With	startsWith	Text
// Ends With	endsWith	Text
// Less Than	lessThan	Number, Date
// Less Than or Equal	lessThanOrEqual	Number
// Greater Than	greaterThan	Number, Date
// Greater Than or Equal	greaterThanOrEqual	Number
// In Range	inRange	Number, Date
// Choose One	empty	Text, Number, Date
//
// case "$in":
// return {[Op.in]: value}
// case "$ne":
// return {[Op.ne]: value}
// case "$between":
// return {[Op.between]: [value[0], value[1]]}
// case "$lt":
// return {[Op.lt]: value}
// case "$lte":
// return {[Op.lte]: value}
// case "$gt":
// return {[Op.gt]: value}
// case "$gte":
// return {[Op.gte]: value}
// case "$not":
// return {[Op.not]: value}
// case "$eq":
// return {[Op.eq]: value}
// // case "$exists":
// //   return value ? Not(IsNull()) : IsNull()
// case "$any":
// return {[Op.any]: value}
// case "$like":
// return {[Op.like]: value}
// case "$ilike":
// return {[Op.iLike]: value}

export const columnFilterInt = [
  "s_billed_times",
  "s_refunded_times",

  "o_nmid",
  "o_odid",
  "o_barcode",
  "o_quantity",
  "o_totalprice",
  "o_discountpercent",
  "o_incomeid",
  "o_gnumber",
  "o_number",
  "s_orderid",
  "s_promocodediscount",
  "s_spp",
  "s_forpay",
  "s_finishedprice",
  "s_pricewithdisc",
  "s_isstorno",
  "s_billedmoney",
  "s_final_commission",
  "s_refundedmoney",
  "s_forpay_withlogistic",
];

export const columnFilterText = [
  "o_subject",
  "o_supplierarticle",
  "o_category",
  "o_brand",
  "o_warehousename",
  "o_oblast",
  "countryname",
  "oblastokrugname",
  "regionname",
  "saleid",
];

export const columnFilterDate = [
  "o_cancel_dt",
  "o_date",
  "s_sale_date",
  "s_refund_date",
  "o_lastchangedate",
  "createdAt",
  "updatedAt",
];

const mapTypeAgGridToBacked = {
  equals: "$eq",
  notEqual: "$not",
  lessThan: "$lt",
  lessThanOrEqual: "$lte",
  greaterThan: "$gt",
  greaterThanOrEqual: "$gte",
  inRange: "$between",
  contains: "$substring",
  notContains: "$notLike",
  startsWith: "$startsWith",
  endsWith: "$endsWith",
}
const mapTypeBackedToAgGrid = _.invert(mapTypeAgGridToBacked);

export function filterAgGridToBackend(filterModel) {
  // console.log({filterModel});
  // barcode: {filterType: 'number', type: 'inRange', filter: 12, filterTo: 3232}
  // filterModel = {odid: {filterType: 'number', type: 'equals', filter: 34}}
  const newFilter = {};
  for(const field in filterModel){
    const filter = filterModel[field];

    if(filter.filterType === 'date'){

      // everywhere will be as an range
      const dateFrom = filter.dateFrom.substring(0, 11) + '00:00:00.000Z';
      let dateTo = filter.dateFrom.substring(0, 11) + '23:59:59.000Z';
      if(filter.dateTo){
        dateTo = filter.dateTo.substring(0, 11) + '23:59:59.000Z';
      }

      if(filter.type === 'inRange'){
        newFilter[field] = {[mapTypeAgGridToBacked[filter.type]]: [dateFrom, dateTo]};
      }else{
        // newFilter[field] = {[mapTypeAgGridToBacked[filter.type]]: filter.dateFrom};
        newFilter[field] = {[mapTypeAgGridToBacked['inRange']]: [dateFrom, dateTo]};
      }
      /** Continue Statement! */
      continue;
    }

    if(filter.type === 'inRange'){
      newFilter[field] = {[mapTypeAgGridToBacked[filter.type]]: [filter.filter, filter.filterTo]};
    }else{
      newFilter[field] = {[mapTypeAgGridToBacked[filter.type]]: filter.filter};
    }
    // field = odid
    // filter = {filterType: 'number', type: 'equals', filter: 234}
  }
  return newFilter;
}

export function sortAgGridToBackend(sortModel){
  let sortArray = []
  for(const key in sortModel){
    sortArray.push(((sortModel[key].sort === 'asc')? '' : '-') + sortModel[key].colId)
  }
  if(sortArray.length){
    return sortArray.join(',');
  }
  return '';
}
// this.props.actions.saveGridFilterModel(this.props.id, filterModel);

export function filterBackendToAgGrid(filters) {
  const filterModel = {};
  for(const field in filters){
    const [field$Type] = Object.keys(filters[field]);
    filterModel[field] = {
      type: mapTypeBackedToAgGrid[field$Type],
      filter: filters[field][field$Type]
    };
    if (columnFilterInt.indexOf(field) !== -1) {
      filterModel[field].filterType = 'number';
    }
    if (columnFilterText.indexOf(field) !== -1) {
      filterModel[field].filterType = 'text';
    }
  }
  return filterModel;
}

export function _zeroPad(num) {
  return num < 10 ? '0' + num : num;
}

