import moment from "moment";

export const dateConfig = {
  // startDate: new Date(start),
  //   endDate:   new Date(end),
  // 'minDate': window.dateSelectorMinDate ? window.dateSelectorMinDate : '01/01/2020',
  // 'maxDate': moment().subtract(1, 'days'),
  'maxDate': moment(),
  'opens': 'left',
  locale: {
    'format': 'DD.MM.YYYY',
    'applyLabel': 'Применить',
    'cancelLabel': 'Сбросить',
    'fromLabel': 'От',
    'toLabel': 'До',
    'daysOfWeek': [
      'Вс',
      'Пн',
      'Вт',
      'Ср',
      'Чт',
      'Пт',
      'Сб',
    ],
    'monthNames': [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    'firstDay': 1,
  }
};

const _zeroPad = (num) => {
  return num < 10 ? '0' + num : num;
}

export const formatDate = (date) => {
  if(typeof date === 'string'){
    date = new Date(date);
  }
  return _zeroPad(date.getFullYear())
    + '-' +
    _zeroPad(date.getMonth() + 1)
    + '-' +
    _zeroPad(date.getDate());
}

export const formatDateTime = (date) => {
  if(!date) return '';
  if(typeof date === 'string'){
    date = new Date(date);
  }
  return _zeroPad(date.getFullYear())
    + '-' +
    _zeroPad(date.getMonth() + 1)
    + '-' +
    _zeroPad(date.getDate())
    + ' ' +
    _zeroPad(date.getHours())
    + ':' +
    _zeroPad(date.getMinutes())
    + ':' +
    _zeroPad(date.getSeconds());
}
