import React, {Component} from "react";
import {connect} from "react-redux";


class AuthCode extends Component
{
  countOfNum = 6
  nums = [];
  numRefs = {}
  sendRef = null
  state = {
    nums: {}
  }
  constructor() {
    super();
    for(let i=1;i<=this.countOfNum;i++){
      this.nums.push(i);
      this.numRefs["num"+i] = null;
      this.state.nums["num"+i] = "";
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleNumRef = this.handleNumRef.bind(this);
    this.handleSendRef = this.handleSendRef.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }
  handleNumRef(ref){
    if (!ref || !ref.dataset) return;
    this.numRefs["num" + ref.dataset.no] = ref
  }
  handleSendRef(ref){
    if (!ref || !ref.dataset) return;
    this.sendRef = ref;
  }
  handleChange(e){
    let {dataset, value} = e.target;

    // bunch set
    if(value.length === this.countOfNum){
      const nums = {}
      for(let i=0;i<this.countOfNum;i++){
        nums["num" + (i+1)] = value[i];
      }
      this.setState({nums});
      this.numRefs["num" + 6].focus()
      // this.sendRef.focus();
      return;
    }

    // if more then countOfNum
    if(value.length > this.countOfNum){
      this.setState({nums: {["num" + dataset.no]: ""}});
      return;
    }

    // normal set
    if(value.length > 1){
      value = value.substring(value.length - 1)
    }
    this.setState({nums: {["num" + dataset.no]: value}});

    // focus
    const num = parseInt(dataset.no);
    if (num < this.countOfNum){
      if(value){
        this.numRefs["num" + (num+1)].focus()
      }
    }else{
      if(value){
        this.numRefs["num" + 6].focus()
        // this.sendRef.focus();
      }
    }
  }
  handleSend(){
    console.log("handleSend", this.sendRef);
  }
  render() {

    return (
      <div className="lb-verification-area">
        888999
        {this.nums.map(n => (
          <div key={n} className="verification-code">
            <input ref={this.handleNumRef} value={this.state.nums["num"+n]} onChange={this.handleChange} data-no={n} maxLength={1} type="number" className="verification-input" name="verification-input-1" />
          </div>
        ))}
        <button ref={this.handleSendRef} onClick={this.handleSend}>send</button>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  // login: (username, password) => dispatch(login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthCode);
