import React from "react";
import Form from "../../../../common/form";
import {connect} from "react-redux";
import {wbKeyInsert, formWbKeyToggle} from "../../../../../store/entities/wb/users";
import {wbHistory} from "../../../../../store/entities/wb/replica";
import './keys.css';
import Key from './key';
import schemaKey from "../../schemas/key";


class Keys extends Form {
  state = {
    data: {
      key_name: "",
      key_stat_v1: "",
      key_v2: "",
    },
    errors: {}
  };
  schema = schemaKey.insert;

  constructor() {
    super();
    this.handleOpenForm = this.handleOpenForm.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    this.props.wbHistory();
  }

  handleOpenForm() {
    this.props.formWbKeyToggle();
  }

  handleUpdate({target}) {
    const {dataset} = target;
    console.log({dataset}, dataset.connectionId)
  }

  doSubmit = async () => {
    const {data} = this.state;
    this.props.wbKeyInsert({
      key_name: data.key_name,
      key_stat_v1: data.key_stat_v1,
      key_v2: data.key_v2,
    });
  };

  render() {
    const {userData, statusCode, loading, messagePublic, formWbKeyIsOpen} = this.props.usersReducer;
    const {history} = this.props.replicaReducer;

    // if(statusCode === 200){
    //   // console.log('NEED REDIRECT');
    //   // toast.info(messagePublic);
    //   // return <Redirect to="/"/>;
    // }

    return (
      <div className="mt-3">
        <div className="col-12">
          <div className="btn-toolbar mb-3">
            <h4 className="px-2">Доступные ключи:</h4>

            <button onClick={this.handleOpenForm}
                    type="button"
                    className="btn btn-secondary btn-sm">
              +
            </button>
          </div>

          {/*{messagePublic &&*/}
          {/*  <div className="w-100 p-3" style={{}}>{messagePublic}</div>*/}
          {/*}*/}

          <div className="col-12" style={{display: 'flex'}}>
            {userData.wb_connections.map(con => (
              <Key key={con.id} connection={con} history={history} />
            ))}
          </div>
          {formWbKeyIsOpen &&
          <div>
            <div
              className={"modal fade " + ((formWbKeyIsOpen) ? 'show' : '')}
              style={{display: (formWbKeyIsOpen) ? 'block' : 'none'}}
              tabIndex="-1" role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Добававление ключа для Wildberries {loading && <img style={{width: '80px'}} src="/spinner.gif" />}</h5>
                    <button onClick={this.handleOpenForm} type="button" className="close" data-dismiss="modal"
                            aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                      {this.renderInput("key_name", "Название")}
                      {this.renderInput("key_stat_v1", "Ключ АПИ статистики", "text")}
                      {this.renderInput("key_v2", "Ключ новое АПИ", "text")}
                    </div>
                    <div className="modal-footer">
                      {!loading && this.renderButton("+ Добавить")}
                      <button onClick={this.handleOpenForm} type="button" className="btn btn-secondary"
                              data-dismiss="modal">Закрыть
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className={"modal-backdrop fade " + ((formWbKeyIsOpen) ? 'show' : '')}></div>
          </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.entities;
};

const mapDispatchToProps = (dispatch) => ({
  wbKeyInsert: (params) => dispatch(wbKeyInsert(params)),
  formWbKeyToggle: () => dispatch(formWbKeyToggle()),
  wbHistory: () => dispatch(wbHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Keys);
